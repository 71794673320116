import {mapGetters, mapActions, mapMutations} from 'vuex'
const DelCategory = () => import("../../components/table/DelToponym/DelCategory")
export const categoryMixin = {
    components: {
      DelCategory
    },
    computed: {
        ...mapGetters([
            'getDataCategory',
            'getActionsCategory'
        ])
    },
    data() {
        return {
          item: {},
          nameCategory: '',
          nameModalUpdate: '',
          name: '',
          sav: false,
          isData: false,     
        }
      },
    methods: {
      ...mapActions(['SET_CATEGORY',]),
      ...mapMutations(['SET_ACTIONS_CATEGORY']),
      addCategory({nameCategory, url, nameModalUpdate}) {
        this.nameModalUpdate = nameModalUpdate
        this.item.url = url
        this.nameCategory = nameCategory
        this.$modal.show('add-category')
        this.sav = true
      },
      save() {
        if (this.sav) {
          this.SET_CATEGORY({
            method: 'post',
            url: this.item.url,
            data: {name: this.name},
            commitName: 'SET_NEW_CATEGORY',
            notify: this.$notify,
            modal: this.$modal,
            modalName: 'add-category'
          })
        } else {
          this.SET_CATEGORY({
            method: 'post',
            url: this.getActionsCategory.url,
            data: {_method: 'put', name: this.name},
            commitName: 'updateCategory',
            id: this.getActionsCategory.id,
            notify: this.$notify,
            modal: this.$modal,
            modalName: 'add-category'
          })
        }
      },
      delItem(item)
      {
        this.SET_ACTIONS_CATEGORY({
          method: 'delete',
          url: this.url,
          commitName: 'delCategory',
          text: 'Вы точно хотите удалить эту запись',
          id: item.row.id
        })
        this.$modal.show(DelCategory,
        {}, {
          width:400,
          height: 'auto',
          scrollable: true
        })
      },
      updateItem(item) {
        this.sav = false
        this.nameModalUpdate = this.options.headings['region']
        this.item.name = item.row.name
        this.name = item.row.name

        this.SET_ACTIONS_CATEGORY({
          method: 'post',
          url: `${this.url + '/' + item.row.id}`,
          data: {_method: 'put', name: this.name},
          commitName: 'updateCategory',
          id: item.row.id
        })
        this.$modal.show('add-category')
      },
      clearCategory()
      {
        this.name = ''
      }
    },

    mounted() {
        this.$store.commit('updateIsLoading', true)
        axios.get('/category')
            .then(response => {
              this.$store.commit('setCategories', response.data)
              this.$store.commit('updateIsLoading', false)
            })
      },
}