<template>
  <div class="dataTables_wrapper dt-bootstrap4 no-footer add-button1">
    <v-client-table
      :columns="columns"
      :data="data"
      :options="options"
      ref="table"
    >
      <div
        class="row justify-content-between d-flex align-items-center w-100"
        :slot="slot_"
        slot-scope="props"
      >
        <div class="col">
          {{ props.row.name }}
        </div>
      </div>
      <div
        class="row justify-content-between d-flex align-items-center w-100"
        slot="actions"
        slot-scope="props"
      >
        <div class="col d-flex justify-content-end">
          <EditBtn v-tooltip="'Изменить'" @click="updateItem(props)" />
          <DeleteBtn v-tooltip="'Удалить'" @click="delItem(props)" />
        </div>
      </div>
    </v-client-table>
  </div>
</template>

<script>
import EditBtn from "../../components/table/EditBtn";
import DeleteBtn from "../../components/table/DeleteBtn";

export default {
  name: "CategoryTable",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: {},
    },
    slot_: {
      type: String,
      default: "",
    },
  },
  methods: {
    delItem(item) {
      this.$emit("remove", item);
    },
    updateItem(item) {
      this.$emit("update", item);
    },
  },
  components: {
    EditBtn,
    DeleteBtn,
  },
};
</script>
