export const vueTableMixin = {
    data () {
        return {       
            entity: null,
            options: {
                texts: {
                    count: "Показано {from}-{to} из {count} записей|{count} записи|Одна запись",
                    first: 'First',
                    last: 'Last',
                    filter: "",
                    filterPlaceholder: "Поиск по названию",
                    limit: "",
                    page: "Страница:",
                    noResults: "Нет данных для отображения",
                    filterBy: "Фильтр по {column}",
                    loading: 'Загрузка. Пожалуйста ждите...',
                    defaultOption: 'Выбрать {column}',
                    columns: 'Колонки'
                },
                columnsClasses: {
                    id: 'w-50px',
                    actions: 'w-100px'
                },
                sortIcon: {
                    base: 'la',
                    up: 'la-long-arrow-up text-success pt-1',
                    down: 'la-long-arrow-down text-success pt-1',
                    is: 'la-arrows-v text-muted pt-1'
                }
            }
        }
      },
    methods: {

        openModal() {
            this.entity = null
            this.$modal.show(this.modalName)
     
        },

        closeModal(){
            this.$modal.hide(this.modalName)
            this.entity = null
        },

        editEntity(entity) {
            this.entity = entity;
            this.$modal.show(this.modalName)
        },

        deleteEntity(id) {
            this.$confirm('Вы действительно хотите удалить эту запись')
                .then(() => {
                    axios.delete(`${this.url}/${id}`).then(() => {
                        this.$refs[this.tableName].refresh()
                        this.$notify({
                          title: 'Success',
                          message: 'Запись успешно удалена',
                          type: 'success'
                        });
                    });

                })
                .catch(() => {
                    this.$notify.error({
                        title: 'Error',
                        message: 'Ошибка удаления'
                    });
                });
        }
    }
}